/* eslint-disable no-param-reassign */
import { GenerateNewRequestWithRefreshToken } from './type';

const generateNewRequestWithRefreshToken: GenerateNewRequestWithRefreshToken =
  async ({
    apiInstance,
    axiosError,
    getRefreshTokenConfig,
    getAccessTokenData,
    refreshTokenPromise,
  }) => {
    const refreshTokenConfig = getRefreshTokenConfig();
    const dateNow = Math.floor(Date.now() / 1000);
    try {
      if (dateNow > getAccessTokenData().refreshTokenExpiry) {
        throw new Error('Failed Refresh Token');
      }

      if (
        typeof refreshTokenConfig === 'undefined' ||
        // Previous success refresh token config need to reset
        (refreshTokenConfig.refreshingTokenStatus === 'success' &&
          dateNow > getAccessTokenData().accessTokenExpiry)
      ) {
        return generateNewRequestWithRefreshToken({
          apiInstance,
          axiosError,
          getAccessTokenData,
          refreshTokenPromise,
          getRefreshTokenConfig: () => getRefreshTokenConfig(true),
        });
      }

      if (refreshTokenConfig.refreshingTokenStatus === 'initial') {
        refreshTokenConfig.refreshingTokenStatus = 'processing';
        const newAccessToken = await refreshTokenPromise();
        refreshTokenConfig.refreshingTokenStatus = 'success';

        // After refresh token API call success,
        // proceed every API call within queue
        refreshTokenConfig.refreshAndRetryQueue.forEach(
          async ({ config, resolve, reject }) => {
            config.headers.Authorization = `Bearer ${newAccessToken}`;
            try {
              const newRequest = await apiInstance.request(config);
              resolve(newRequest);
            } catch (err) {
              refreshTokenConfig.refreshingTokenStatus = 'failed';
              reject(err);
            } finally {
              refreshTokenConfig.refreshAndRetryQueue = [];
            }
          },
        );
      }

      if (refreshTokenConfig.refreshingTokenStatus === 'success') {
        // Prevent request loop if success request token
        // still makes 401 invalid token for new request;
        if (
          axiosError.config.headers.Authorization ===
          `Bearer ${getAccessTokenData().accessToken}`
        ) {
          throw new Error('Token Alreadry Refreshed');
        }

        // Proceed new incoming API call with new API token
        axiosError.config.headers.Authorization = `Bearer ${
          getAccessTokenData().accessToken
        }`;
        const newRequest = await apiInstance.request(axiosError.config);
        return newRequest;
      }
    } catch (err) {
      if (typeof refreshTokenConfig !== 'undefined') {
        refreshTokenConfig.refreshingTokenStatus = 'failed';
        refreshTokenConfig.refreshAndRetryQueue = [];
      }
      return Promise.reject(err);
    }

    // refreshRequestConfig.refreshingTokenStatus === 'processing'
    // while refresh token API call still in progress,
    // queue every new incoming API call
    return new Promise<void>((resolve, reject) => {
      getRefreshTokenConfig().refreshAndRetryQueue.push({
        config: axiosError.config,
        resolve,
        reject,
      });
    });
  };

export default generateNewRequestWithRefreshToken;
